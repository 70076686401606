@import "~antd/lib/style/themes/default.less";

.mainmenu {
  margin: 20px 0;
  position: relative;

  &__logo {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 20px;

    @media screen and (min-width: @screen-md) {
      left: 16%;
    }

    @media screen and (min-width: @screen-xl) {
      left: 24%;
    }

    img {
      width: 55px;

      @media screen and (min-width: @screen-md) {
        width: auto;
      }
    }

    & + div.ant-col-offset-8 {
      margin-left: 80%;

      @media screen and (min-width: @screen-md) {
        margin-left: 33.33333333%;
      }

      .ant-menu-horizontal {
        border-bottom: none;

        .anticon.anticon-ellipsis {
          font-size: 25px;
        }
      }
    }
  }
}

@primary-color: #54075b;