@import "~antd/lib/style/themes/default.less";

.project {
  &__title.ant-typography {
    color: @primary-color;
    margin-top: @margin-lg;

    .ant-tag {
      position: relative;
      top: -5px;
    }
  }

  &__description {
    font-size: 16px;
    margin-bottom: @margin-lg;
  }

  &__gallery {
    .ant-image {
      width: 25%;
      margin-right: @margin-xs;
      margin-bottom: @margin-xs;
    }
  }

  .ant-divider {
    border-top-width: 5px;
    margin-top: @margin-sm;
  }
}

@primary-color: #54075b;