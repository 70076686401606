@import "~antd/lib/style/themes/default.less";

.page-footer {
  .anticon {
    font-size: 35px;
    margin-right: 15px;
  }

  p {
    font-size: 12px;
  }

  &__row-1 {
    h3.ant-typography {
      margin-bottom: 0;
    }
  }

  &__row-2 {
    margin-top: 12px;
    margin-bottom: 12px;

    &__logo {
      @media screen and (min-width: @screen-md) {
        display: none;
      }
    }

    &__title {
      @media screen and (min-width: @screen-md) {
        display: none;
      }
    }
  }
}

@primary-color: #54075b;