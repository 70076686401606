@import "~antd/lib/style/themes/default.less";

.block-about-us {
  &__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(../../assets/images/hanna-morris-6BhCI5uWiFQ-unsplash.jpg);
    height: 555px;
    width: 100%;
  }

  &__button {
    margin-top: 35px;
  }
}

@primary-color: #54075b;