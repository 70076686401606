.page-banner {
  height: 235px;
  background-color: #e2e2e2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__darker {
    background-color: rgb(0 0 0 / 20%);
    height: 100%;
  }

  &__title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    h2.ant-typography {
      color: white;
      text-transform: capitalize;
    }
  }
}

@primary-color: #54075b;