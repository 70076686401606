@import "~antd/lib/style/themes/default.less";

.project-teaser {
  &.ant-card {
    margin-bottom: @margin-md;

    .ant-card-cover {
      max-height: 220px;
      overflow: hidden;
    }
  }
}

@primary-color: #54075b;