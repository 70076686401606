@import "antd/dist/antd.less";
@import "~antd/lib/style/themes/default.less";

/*
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small
*/

.text-center {
  text-align: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.img-responsive {
  width: 100%;
}

.page-block-spacing {
  margin-top: 40px;
  padding: 40px 0;
}

.ant-divider {
  border-top-color: @primary-color;
}

@primary-color: #54075b;