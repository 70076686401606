@import "~antd/lib/style/themes/default.less";

.summary-in-numbers {
  background-color: @primary-color;
  padding: 20px 20px 20px 65px;

  .ant-statistic {
    margin-bottom: 20px;

    .ant-statistic-title,
    .ant-statistic-content {
      color: white;
    }

    .ant-statistic-content-suffix .anticon {
      font-size: 16px;
      position: relative;
      top: -3px;
    }

    @media screen and (min-width: @screen-md) {
      margin-bottom: 0;
    }
  }
}

@primary-color: #54075b;