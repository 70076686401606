@import "~antd/lib/style/themes/default.less";

.block-header {
  &__pre-title.ant-typography {
    color: @primary-color;

    & + h2.ant-typography {
      margin-top: 0;
    }
  }
}

@primary-color: #54075b;