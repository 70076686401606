@import "~antd/lib/style/themes/default.less";

.homepage-slider {
  &__card.ant-card-bordered {
    border: none;

    .ant-card-cover {
      @media screen and (min-width: @screen-lg) {
        overflow: hidden;
        height: calc(100vh - 86px - 65px);
      }
    }

    .ant-card-body {
      background-color: @primary-color;

      .ant-card-meta-title {
        color: white;
      }
    }
  }

  ul.slick-dots {
    right: 3000%;

    @media screen and (min-width: @screen-md) {
      right: 0;
    }
  }
}

@primary-color: #54075b;