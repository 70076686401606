.ataajy-bank-account {
  margin-bottom: 12px;

  &.small {
    .ant-descriptions-header {
      margin-bottom: 0;
    }

    .ant-descriptions-item {
      padding-bottom: 0;
    }
  }
}

@primary-color: #54075b;