@import "~antd/lib/style/themes/default.less";

.block-our-mission {
  .ant-col {
    margin-bottom: 20px;

    @media screen and (min-width: @screen-md) {
      margin-bottom: 0;
    }
  }

  &__button {
    margin-top: 20px;
  }
}

@primary-color: #54075b;